"use strict";
/*global window*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBrowserInfo = void 0;
var lodash_1 = require("lodash");
function getRenderer() {
    try {
        var canvas = document.createElement('canvas');
        if (canvas) {
            var context = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
            if (context) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                var info = context.getExtension('WEBGL_debug_renderer_info');
                if (info) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    return context.getParameter(info.UNMASKED_RENDERER_WEBGL);
                }
            }
        }
    }
    catch (err) {
        console.error("Cant get renderer:" + err);
    }
    return null;
}
function getBrowserInfo() {
    var screen = lodash_1.pick(window.screen, ['height', 'width']);
    var devicePixelRatio = window.devicePixelRatio;
    var userAgent = window.navigator.userAgent;
    var meta = {
        screen: screen,
        devicePixelRatio: devicePixelRatio,
    };
    var renderer = getRenderer();
    return {
        userAgent: userAgent,
        meta: meta,
        renderer: renderer
    };
}
exports.getBrowserInfo = getBrowserInfo;
