import {UAParser} from 'ua-parser-js';
import {Referrer} from '../constants/room.constants';
import {DeviceCategory} from '../constants/utils.constant';
import includes from 'lodash/includes';
// eslint-disable-next-line no-undef
import IBrowser = UAParser.IBrowser;

export enum Environments {
    desktop = 'desktop',
    mobile = 'mobile',
    phone = 'phone',
    tablet = 'tablet'
}

export interface ITsEnvironmentDetect {
    isDesktop(displayTabletAsDesktop?: boolean): boolean;
    isMobile(displayTabletAsDesktop?: boolean): boolean;
    isPhone(): boolean;
    isTablet(displayTabletAsDesktop?: boolean): boolean;
    isAndroid(): boolean;
    isIOS(): boolean;
    isIE11(): boolean;
    isChrome(): boolean;
    isFF(): boolean;
    isSafari(): boolean;
    isEdge(): boolean;

    majorVersion(): number;
    browserVersion(): string;

    deviceType(): string | undefined;
    os(): UAParser.IOS;
    osName(): string | undefined;
    browser(): UAParser.IBrowser;

    isEnvironment(env: Environments, displayTabletAsDesktop?: boolean): boolean;

    isApplicationWebView(ref: string): boolean;
    isAudioSupported(): boolean;
    deviceCategory(displayTabletAsDesktop?: boolean): DeviceCategory | null;
    deviceCategoryForView(displayTabletAsDesktop?: boolean): DeviceCategory | null;
    getDeviceDetails(): UAParser.IResult;
    isWebRTCEnabledAppleDevice(): boolean;
    isLinux(): boolean;
    isMac(): boolean;
    isWindows(): boolean;
}

export enum OSTypes {
    Mac = 'mac',
    Linux = 'linux',
    Windows = 'windows'
}

/**
 * TsEnvironmentDetectService checks if the current browser is the
 * running in a mobile (phone, tablet) or desktop environment
 */
export class TsEnvironmentDetect implements ITsEnvironmentDetect {
    private _parser: UAParser;
    private readonly _os: UAParser.IOS;
    private readonly _deviceType?: string;
    private readonly _osName?: string;
    private readonly _browser: UAParser.IBrowser;

    public constructor(userAgent = '') {
        this._parser = new UAParser(userAgent);

        this._deviceType = this._parser.getDevice().type;
        this._os = this._parser.getOS();
        this._osName = this._os.name;
        this._browser = this._parser.getBrowser();
    }

    private getDisplayTabletAsDesktop(displayTabletAsDesktop: boolean | undefined): boolean {
        return displayTabletAsDesktop === undefined
            ? !!(typeof localStorage === 'undefined' ? false : localStorage.getItem('displayTabletAsDesktop'))
            : displayTabletAsDesktop;
    }

    public isWebRTCEnabledAppleDevice(): boolean {
        return !!(this.isIOS() && this._os && this._os.version && this._os.version >= '11.0');
    }

    public getDeviceDetails(): UAParser.IResult {
        return this._parser.getResult();
    }

    public isDesktop(displayTabletAsDesktop?: boolean): boolean {
        // Unlike all other types of devices (mobile, tablet, smarttv, wearable, etc)
        // desktops and laptops are not identified as such. They simply have
        // an undefined type (which also happens on some Android tablets, though).
        // noinspection PointlessBooleanExpressionJS
        return !!(
            (typeof this._deviceType === 'undefined' && !this.isAndroid()) ||
            (this.isTablet(displayTabletAsDesktop) && this.getDisplayTabletAsDesktop(displayTabletAsDesktop))
        );
    }

    // UAParser uses 'mobile' for phones, but we also want the ability to test
    // for devices that are either phones or tablets and these are generally
    // called 'mobile'. so isMobile means deviceType equals to 'mobile', 'tablet'
    // or osName === Android (since some android tablet report deviceType 'udefined')
    public isMobile(displayTabletAsDesktop?: boolean): boolean {
        return (
            this.isPhone() ||
            (this.isTablet(displayTabletAsDesktop) && !this.getDisplayTabletAsDesktop(displayTabletAsDesktop))
        );
    }

    public isPhone(): boolean {
        return this._deviceType === 'mobile';
    }

    public isTablet(displayTabletAsDesktop?: boolean): boolean {
        return (
            this.getDisplayTabletAsDesktop(displayTabletAsDesktop) ||
            (this._deviceType === 'undefined' && this.isAndroid()) ||
            this._deviceType === 'tablet'
        );
    }

    public isAndroid(): boolean {
        return this._osName === 'Android';
    }

    public isIOS(): boolean {
        return this._osName === 'iOS';
    }

    public isIE11(): boolean {
        return this._browser.name === 'IE' && this._browser.major === '11';
    }

    public isChrome(): boolean {
        return this._browser.name === 'Chrome';
    }

    public isFF(): boolean {
        return this._browser.name === 'Firefox';
    }

    public isSafari(): boolean {
        return includes(this._browser.name, 'Safari');
    }

    public isEdge(): boolean {
        return this._browser.name === 'Edge';
    }

    public majorVersion(): number {
        return parseInt(this._browser.major || '', 10);
    }

    public browserVersion(): string {
        return this._browser.version || '';
    }

    public deviceType(): string | undefined {
        return this._deviceType;
    }

    public deviceCategory(displayTabletAsDesktop?: boolean): DeviceCategory | null {
        if (this.isTablet(displayTabletAsDesktop)) {
            return DeviceCategory.tablet;
        } else if (this.isMobile(displayTabletAsDesktop)) {
            return DeviceCategory.phone;
        } else if (this.isDesktop(displayTabletAsDesktop)) {
            return DeviceCategory.desktop;
        }

        return null;
    }

    // Category for viewing (for example, tablet might get desktop view)
    public deviceCategoryForView(displayTabletAsDesktop?: boolean): DeviceCategory | null {
        if (this.isDesktop(displayTabletAsDesktop)) {
            return DeviceCategory.desktop;
        } else if (this.isMobile(displayTabletAsDesktop)) {
            return DeviceCategory.phone;
        } else if (this.isTablet(displayTabletAsDesktop)) {
            return DeviceCategory.tablet;
        }

        return null;
    }

    public os(): UAParser.IOS {
        return this._os;
    }

    public osName(): string | undefined {
        return this._osName;
    }

    public isLinux(): boolean {
        const clientOs = this._osName && this._osName.toLowerCase();

        return !!(clientOs && clientOs.includes(OSTypes.Linux));
    }

    public isMac(): boolean {
        const clientOs = this._osName && this._osName.toLowerCase();

        return !!(clientOs && clientOs.includes(OSTypes.Mac));
    }

    public isWindows(): boolean {
        const clientOs = this._osName && this._osName.toLowerCase();

        return !!(clientOs && clientOs.includes(OSTypes.Windows));
    }

    public browser(): IBrowser {
        return this._browser;
    }

    public isEnvironment(env: Environments, displayTabletAsDesktop?: boolean): boolean {
        return (
            (env === Environments.desktop && this.isDesktop(displayTabletAsDesktop)) ||
            (env === Environments.mobile && this.isMobile(displayTabletAsDesktop)) ||
            (env === Environments.phone && this.isPhone()) ||
            (env === Environments.tablet && this.isTablet(displayTabletAsDesktop))
        );
    }

    public isApplicationWebView(ref: string): boolean {
        return includes([Referrer.ANDROID_APPLICATION, Referrer.IOS_APPLICATION], ref);
    }

    public isAudioSupported(): boolean {
        return !this.isIOS() || (this.isIOS() && this.isSafari());
    }
}
