/*global window*/

import {pick} from 'lodash';

function getRenderer(): any {
    try {
        const canvas = document.createElement('canvas');

        if (canvas) {
            const context = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');

            if (context) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const info = context.getExtension('WEBGL_debug_renderer_info');

                if (info) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    return context.getParameter(info.UNMASKED_RENDERER_WEBGL);
                }
            }
        }
    } catch (err) {
        console.error(`Cant get renderer:${err}`);
    }

    return null;
}

export interface IBrowserInfo {
    userAgent: string;
    meta: any;
    renderer: any;
}

export function getBrowserInfo(): IBrowserInfo {
    const screen = pick(window.screen, ['height', 'width']);
    const devicePixelRatio = window.devicePixelRatio;
    const userAgent = window.navigator.userAgent;

    const meta = {
        screen,
        devicePixelRatio,
    };

    const renderer = getRenderer();

    return {
        userAgent,
        meta,
        renderer
    };
}

